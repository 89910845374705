var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("mew-stepper", {
    attrs: { items: _vm.stepperItems, "on-step": _vm.step },
    scopedSlots: _vm._u(
      [
        _vm.step === 1
          ? {
              key: "stepperContent1",
              fn: function () {
                return [
                  _c(
                    "form",
                    {
                      on: {
                        submit: function ($event) {
                          $event.preventDefault()
                          return _vm.unlockBtn.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c(
                        "v-row",
                        { staticClass: "align-end justify-start mb-3 mb-md-5" },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "subtitle-1 font-weight-bold grey--text",
                                },
                                [_vm._v("STEP 1.")]
                              ),
                              _c(
                                "div",
                                { staticClass: "headline font-weight-bold" },
                                [_vm._v(" Enter your Mnemonic Phrase ")]
                              ),
                              _c("p", { staticClass: "mb-3" }, [
                                _vm._v(
                                  " Please type the mnemonic phrase you wrote down in the right order. "
                                ),
                              ]),
                              _c(
                                "div",
                                { staticClass: "d-flex flex-row-reverse pb-4" },
                                [
                                  _c("v-select", {
                                    staticStyle: { "max-width": "150px" },
                                    attrs: {
                                      "hide-details": "",
                                      dense: "",
                                      "item-text": "label",
                                      "item-value": "value",
                                      items: _vm.mnemonicOptions,
                                      label: "",
                                      outlined: "",
                                    },
                                    model: {
                                      value: _vm.length,
                                      callback: function ($$v) {
                                        _vm.length = $$v
                                      },
                                      expression: "length",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "phrase-block",
                                { staticClass: "mb-8" },
                                [
                                  _c(
                                    "v-row",
                                    _vm._l(_vm.length, function (n) {
                                      return _c(
                                        "v-col",
                                        {
                                          key: "mnemonicInput" + n,
                                          attrs: {
                                            cols: "6",
                                            lg: "3",
                                            md: "3",
                                            sm: "4",
                                          },
                                        },
                                        [
                                          _c("v-text-field", {
                                            ref: "mnemonicInput" + n,
                                            refInFor: true,
                                            attrs: {
                                              name: "mnemonicInput" + n,
                                              label: n + ".",
                                              autocomplete: "off",
                                              autofocus: n === 1,
                                            },
                                            model: {
                                              value: _vm.phrase[n],
                                              callback: function ($$v) {
                                                _vm.$set(_vm.phrase, n, $$v)
                                              },
                                              expression: "phrase[n]",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c("mew-expand-panel", {
                                attrs: {
                                  "has-dividers": true,
                                  "panel-items": _vm.extraWordPanel,
                                  "idx-to-expand": null,
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "panelBody1",
                                      fn: function () {
                                        return [
                                          _c(
                                            "div",
                                            { staticClass: "px-5" },
                                            [
                                              _c("mew-input", {
                                                attrs: {
                                                  type: "password",
                                                  label: "Enter Extra word",
                                                  placeholder:
                                                    "Enter your extra word",
                                                },
                                                model: {
                                                  value: _vm.extraWord,
                                                  callback: function ($$v) {
                                                    _vm.extraWord = $$v
                                                  },
                                                  expression: "extraWord",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  false,
                                  3643731195
                                ),
                              }),
                              _c(
                                "v-row",
                                {
                                  staticClass: "pt-4",
                                  attrs: {
                                    dense: "",
                                    align: "center",
                                    justify: "center",
                                  },
                                },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "text-center",
                                      attrs: {
                                        cols: "12",
                                        "align-self": "center",
                                      },
                                    },
                                    [
                                      _c("mew-button", {
                                        attrs: {
                                          title: "Next",
                                          "btn-size": "xlarge",
                                          disabled: !_vm.isValidMnemonic,
                                        },
                                        nativeOn: {
                                          click: function ($event) {
                                            return _vm.unlockBtn.apply(
                                              null,
                                              arguments
                                            )
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "text-center",
                                      attrs: {
                                        cols: "12",
                                        "align-self": "center",
                                      },
                                    },
                                    [
                                      _c("mew-button", {
                                        attrs: {
                                          title: "Clear",
                                          "btn-size": "xlarge",
                                          "btn-style": "transparent",
                                        },
                                        nativeOn: {
                                          click: function ($event) {
                                            return _vm.clearFields.apply(
                                              null,
                                              arguments
                                            )
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            }
          : null,
        _vm.step === 2
          ? {
              key: "stepperContent2",
              fn: function () {
                return [
                  _c(
                    "v-row",
                    { staticClass: "align-end justify-start" },
                    [
                      _c("v-col", { attrs: { cols: "12" } }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "subtitle-1 font-weight-bold grey--text",
                          },
                          [_vm._v("STEP 2.")]
                        ),
                        _c(
                          "div",
                          { staticClass: "headline font-weight-bold mb-5" },
                          [_vm._v(" Select Address and Network ")]
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c("access-wallet-address-network", {
                    attrs: {
                      "handler-wallet": _vm.walletInstance,
                      "selected-path": _vm.selectedPath,
                      paths: _vm.parsedPaths,
                      "hide-networks": _vm.switchAddress,
                    },
                    on: { setPath: _vm.setPath, unlock: _vm.accessWallet },
                  }),
                ]
              },
              proxy: true,
            }
          : null,
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }